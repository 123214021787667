<template>
  <div class="vertify_container">
    <h1 class="title">Application Form</h1>
    <div class="vertify_chosing mt-50px">
      <div class="vertify_person">
        <router-link to="/user/personapplication/1" class="img_cover">
        </router-link>
        <div class="vertify_title text-center">
          INDIVIDUAL & SOLE TRADER
        </div>
        <div class="vertify_divider"></div>
        <div class="vertify_subtitle">个人/个体经营</div>
      </div>
      <div class="vertify_entity">
        <router-link to="/user/entityapplication/1" class="img_cover">
        </router-link>
        <div class="vertify_title text-center">
          ENTITY
        </div>
        <div class="vertify_divider"></div>
        <div class="vertify_subtitle">单位/公司</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    if (!window.localStorage.getItem('entity_panes')) {
      window.localStorage.setItem('entity_panes', JSON.stringify([
        { title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }
      ]))
    };
    if (!window.localStorage.getItem('panes')) {
      window.localStorage.setItem('panes', JSON.stringify([
        { title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }
      ]))
    }
  }
}
</script>

<style scoped>
.vertify_person,.vertify_entity{
  width: 145px;
  margin-right: 20px;
}
.vertify_container{
  padding-top: 100px;
}
.title{
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.vertify_chosing{
  display: flex;
  justify-content: center;
}
.img_cover{
  border: 10px solid #ececec;
  border-radius: 50%;
  display: block;
  width: 145px;
  height: 145px;
  transition: .2s;
}
.vertify_person .img_cover{
  background: url(../../../assets/img/individual-150x150.png) no-repeat center;
}
.vertify_entity .img_cover{
  background: url(../../../assets/img/group-150x150.png) no-repeat center;
}

.vertify_person:hover .img_cover{
  border: 10px solid #e96656;
}
.vertify_entity:hover .img_cover{
  border: 10px solid #34d293;
}
.vertify_title{
  margin-top: 20px;
  font-size: 17px;
  color: #404040;
  font-weight: bold;
}

.vertify_person .vertify_divider{
  width: 80%;
  margin: 10px auto 0;
  border: 1px solid #e96656;
}
.vertify_entity .vertify_divider{
  width:40%;
  margin: 10px auto 0;
  border: 1px solid #34d293;
}
.vertify_subtitle{
  color: #808080;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

</style>
